<template>
  <div class="CustomAuth">
    <div v-if="userIsSignedIn">
      You are signed in!
    </div>
    <template v-else-if="component.default_ui === undefined || component.default_ui">
      <InputBox
        :component="emailInputComponent"
        :componentId="emailInputComponent.id"
        :componentData="email"
        v-bind="{ form, userData }"
        @update="setEmail"
      />
      <InputBox
        :component="passwordInputComponent"
        :componentId="passwordInputComponent.id"
        :componentData="password"
        v-bind="{ form, userData }"
        @update="setPassword"
      />
    </template>
    <button class="CustomButton" v-if="!userIsSignedIn" type="button" @click="createAccount">
      {{ button_text || 'Create Account' }}
    </button>

    <div v-if="!serviceIsPresent">
      Service Package for {{ service }} was not found on this page

      <button
        v-if="_isInWebApp()"
        class="CustomButton"
        type="button"
        @click="userIsSignedIn = !userIsSignedIn"
      >
        {{ userIsSignedIn ? 'Mock Account Signout' : 'Mock Account Creation' }}
      </button>
    </div>
  </div>
</template>
<script>
import { unpack } from '@/helpers/computed'
import notify from '@/helpers/notifyLite'
import computedValues from './editor/helpers/computedValues'
/* Assume Window.Firebase.auth() is present */
// import firebase from '@firebase/app'
// import '@firebase/auth'
const firebase = window.firebase

export default {
  components: {
    InputBox: () => import('./InputBox'),
  },
  inject: { _isInWebApp: { default: () => () => {} }, _localDataGet: { default: () => () => {} } },
  props: { component: Object, userData: Object, form: Object },
  data() {
    return { email: '', password: '' }
  },
  computed: {
    ...unpack('component', ['service', 'key', 'id', 'button_text']),
    serviceIsPresent() {
      switch (this.service) {
        case 'firebase-auth':
          return Boolean(firebase && firebase.auth && firebase.auth())

        default:
          return false
      }
    },
    passwordInputComponent() {
      return {
        doNotSave: true,
        id: `${this.component.key}__password`,
        key: 'password',
        isRequired: true,
        multiline: false,
        type: 'InputBox',
        input_type: 'password',
        protected: this.component.protected,
        generatedComponent: true,
      }
    },
    emailInputComponent() {
      return {
        doNotSave: true,
        id: `${this.component.key}__email`,
        key: 'email',
        isRequired: true,
        multiline: false,
        type: 'InputBox',
        validate_value: true,
        validation_formula: 'email_address',
        protected: this.component.protected,
        generatedComponent: true,
      }
    },
    userIsSignedIn: {
      get() {
        return this.userData[this.key || this.id] === 'signed_in'
      },
      set(v) {
        if (v) this.$emit('update', [this.key || this.id, 'signed_in'])
        else this.$emit('update', [this.key || this.id, 'not_signed_in'])
      },
    },
  },
  mounted() {
    switch (this.service) {
      case 'firebase-auth': {
        if (firebase && firebase.auth && firebase.auth().currentUser) this.userIsSignedIn = true
        break
      }

      default:
        break
    }
  },
  methods: {
    setPassword(e) {
      this.password = e[1]
    },
    setEmail(e) {
      this.email = e[1]
      this.$emit('update', ['email', e[1]])
    },
    async signOut() {
      switch (this.service) {
        case 'firebase-auth': {
          await firebase.auth().signOut()
          this.userIsSignedIn = false
          break
        }

        default:
          break
      }
    },
    async createAccount() {
      switch (this.service) {
        case 'firebase-auth':
          return await this.createFirebaseAccount()

        default:
          break
      }
    },
    async createFirebaseAccount() {
      const password =
        this.password ||
        this._localDataGet(
          this.component.password_key &&
            this.component.password_key.startsWith('{{') &&
            this.component.password_key.endsWith('}}')
            ? this.component.password_key.slice(2, -2).trim()
            : this.component.password_key
        )
      const email =
        this.email || computedValues(this.userData, this.component.email_field, this.form)

      if (!this.serviceIsPresent) {
        throw new Error(`Service Package for ${this.service} was not found on the page`)
      }

      try {
        await firebase.auth().createUserWithEmailAndPassword(email, password)
        this.userIsSignedIn = true
        return true
      } catch (error) {
        console.error(error)
        notify(`Creating user for ${this.service}`, error, {
          form: this.form,
          userData: this.userData,
        })
        return false
      }

      // Firebase.auth().signInWithEmailAndPassword(email, password)
    },
  },
}
</script>
